import { FaixaPromo } from './styles/planosPrecos.style';

const FaixaBlackFriday = () => {
return(
    <FaixaPromo>
        <div> PROMO Feliz 2025! Agora vai!</div>
        <p>Bônus de 01 ano no plano Bi e de 02 anos planos Tri.</p>
    </FaixaPromo>
)
}

export default FaixaBlackFriday;